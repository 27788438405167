export class Template {
    constructor(element) {
        this.element = element;
    }
    static fromElementData(data) {
        const element = data.templateId === undefined
            ? this.defaultElement()
            : document.getElementById(data.templateId) ?? this.defaultElement();
        return new Template(element);
    }
    static defaultElement() {
        let element = undefined;
        element = document.createElement("div");
        element.innerHTML = "{text}";
        return element;
    }
    get callback() {
        return (optionData) => {
            let finalHTML = this.element.innerHTML;
            const matches = [...finalHTML.matchAll(/{([^}]+)}/g)];
            for (const match of matches) {
                let value = "";
                if (optionData.hasOwnProperty(match[1])) {
                    [, value] = match;
                }
                finalHTML = finalHTML.replaceAll(match[0], optionData[value]);
            }
            const finalElement = document.createElement("div");
            finalElement.innerHTML = finalHTML;
            return finalElement;
        };
    }
}
